import queryString from 'query-string';
import axios from './axios';
import api from './api';
import { hashCode, isArray } from '../helpers/javascript';
import { API_VIDEO } from './app.config';

// cloudinary.config({
//   cloud_name: 'dakp804eh',
//   api_key: '865461935889138',
//   api_secret: 'M1j9Vao3euRJloFqKohOjkKEYlE',
//   upload_prefix: '/cloudinary/api'
// });

export const deleteAssets = ({ publicIds = [], options }) =>
  new Promise((resolve, reject) => {
    api({
      url: `${API_VIDEO}/deleteassets`,
      method: 'POST',
      data: { publicIds, options }
    })
      .then(res => {
        resolve(res.data);
      })
      .catch(e => {
        reject(e);
      });
  });

export const find = ({ query, page, limit }) =>
  new Promise((resolve, reject) => {
    api({
      url: `${API_VIDEO}/gallery`,
      method: 'POST',
      data: { query, page, limit }
    })
      .then(res => {
        resolve(res.data);
      })
      .catch(e => {
        reject(e);
      });
  });

export const findAll = ({ query }) =>
  new Promise((resolve, reject) => {
    api({
      url: `${API_VIDEO}/galleryall`,
      method: 'POST',
      data: { query }
    })
      .then(res => {
        resolve(res.data);
      })
      .catch(e => {
        reject(e);
      });
  });

export const findAllTags = () =>
  new Promise((resolve, reject) => {
    api({
      url: `${API_VIDEO}/alltags`,
      method: 'POST',
      data: {}
    })
      .then(res => {
        resolve(res.data);
      })
      .catch(e => {
        reject(e);
      });
  });

export const findAllTagsUser = ({ folder, type }) =>
  new Promise((resolve, reject) => {
    api({
      url: `${API_VIDEO}/alltagsquery`,
      method: 'POST',
      data: { folder, type }
    })
      .then(res => {
        resolve(res.data);
      })
      .catch(e => {
        reject(e);
      });
  });

export const findFontLibrary = ({ query = '', page, username, limit }) =>
  new Promise((resolve, reject) => {
    const folders = ['woxo-video-fonts-presets/*'];
    if (username) {
      folders.push(`${cloudinaryParentFolder}/${username}`);
    }
    const foldersQuery = `(${folders.map(folder => `folder=${folder}`).join(' OR ')})`;
    const initQuery = `${
      query !== '' ? `filename:${encodeURIComponent(query)}* AND` : ''
    } ${foldersQuery} AND resource_type=raw AND format=(ttf OR otf OR woff) `;
    api({
      url: `${API_VIDEO}/gallery`,
      method: 'POST',
      data: { query: initQuery, page, limit }
    })
      .then(res => {
        resolve(res.data);
      })
      .catch(e => {
        reject(e);
      });
  });

const cloudinaryParentFolder = 'woxo-videos-user-gallery';

export const findMusicLibrary = ({ mood, page, username, limit }) =>
  new Promise((resolve, reject) => {
    const folders = [`woxo-video/audios-v2/${mood && mood !== 'music' ? mood : '*'}`];
    if (username) {
      folders.push(`${cloudinaryParentFolder}/${username}`);
    }
    const foldersQuery = `(${folders.map(folder => `folder=${folder}`).join(' OR ')})`;
    const initQuery = `MOODHALO AND ${foldersQuery} AND resource_type=video AND format=(mp3 OR m4a OR wma OR wav OR flac or tiff OR aif OR wmv or ac3 or acc) `;
    api({
      url: `${API_VIDEO}/gallery`,
      method: 'POST',
      data: { query: initQuery, page, limit }
    })
      .then(res => {
        resolve({ assets: res.data.resources, ...res.data });
      })
      .catch(e => {
        reject(e);
      });
  });
export const tagsAssets = ({ query, resourceType, page, limit }) =>
  new Promise((resolve, reject) => {
    api({
      url: `${API_VIDEO}/tagsassets`,
      method: 'POST',
      data: { query, resourceType, page, limit }
    })
      .then(res => {
        resolve(res.data);
      })
      .catch(e => {
        reject(e);
      });
  });
export const libraryAssetsSafely = ({ query, resourceType, page, limit, videoShape = '9:16' }) =>
  new Promise((resolve, reject) => {
    libraryAssets({ query, resourceType, page, limit, videoShape })
      .then(data => {
        if (data.total_results > 0 || resourceType === 'image') return data;
        return libraryAssets({
          query,
          resourceType: resourceType === 'video' ? 'image' : 'image',
          page,
          limit,
          videoShape
        });
      })
      .then(data => {
        resolve(data);
      })
      .catch(e => reject(e));
  });
export const libraryAssets = ({ query, resourceType, page, limit, videoShape = '9:16' }) =>
  new Promise((resolve, reject) => {
    let orientation = 'portrait';
    if (videoShape === '16:9') {
      orientation = 'landscape';
    } else if (videoShape === '1:1' || videoShape === '4:5') {
      orientation = 'square';
    }

    const params = queryString.stringify({
      query,
      resourceType,
      page,
      limit,
      orientation
    });
    api({
      url: `${API_VIDEO}/library?${params}`,
      method: 'GET'
    })
      .then(res => {
        if (resourceType === 'video') {
          const { videos = [], ...rest } = res.data;
          const assets = videos.map(p => {
            const videosFiles = p.video_files
              .sort((a, b) => b.width - a.width)
              .filter(v => v.quality !== 'hls');
            const urlFull = `${videosFiles[0].link}&h=${videosFiles[0].height}&w=${videosFiles[0].width}`;
            const preview = `${videosFiles[videosFiles.length - 1].link}&h=${
              videosFiles[videosFiles.length - 1].height
            }&w=${videosFiles[videosFiles.length - 1].width}`;
            const cover = p.video_pictures[0].picture;
            return Object.assign(p, {
              resource_type: resourceType,
              asset_id: p.id,
              image: p.image && p.image !== '' ? p.image : cover,
              // secure_url: p.video_files.find(v => v.quality === 'sd' || v.quality === 'hd').link
              secure_url: `${urlFull}&preview=${encodeURIComponent(
                preview
              )}&cover=${encodeURIComponent(cover)}`
            });
          });
          resolve({ assets, ...rest });
        } else {
          const { photos = [], ...rest } = res.data;
          const assets = photos.map(p => {
            const urlFull = p.src.original;
            const preview = p.src.large;
            const cover = p.src.small;
            return Object.assign(p, {
              resource_type: 'image',
              asset_id: p.id,
              secure_url: `${urlFull}?preview=${encodeURIComponent(
                preview
              )}&cover=${encodeURIComponent(cover)}`
            });
          });
          resolve({ assets, ...rest });
        }
      })
      .catch(e => {
        reject(e);
      });
  });
export const updateAssets = ({ publicIds, options }) =>
  new Promise((resolve, reject) => {
    api({
      url: `${API_VIDEO}/updateassets`,
      method: 'POST',
      data: { publicIds, options }
    })
      .then(res => {
        resolve(res.data);
      })
      .catch(e => {
        reject(e);
      });
  });
export const copyAssets = ({ url, options }) =>
  new Promise((resolve, reject) => {
    api({
      url: `${API_VIDEO}/copyasset`,
      method: 'POST',
      data: { url, options }
    })
      .then(res => {
        resolve(res.data);
      })
      .catch(e => {
        reject(e);
      });
  });

export const addTags = ({ tag, publicIds, options }) =>
  new Promise((resolve, reject) => {
    api({
      url: `${API_VIDEO}/insertags`,
      method: 'POST',
      data: { tag, publicIds, options }
    })
      .then(res => {
        resolve(res.data);
      })
      .catch(e => {
        reject(e);
      });
  });

export const removeTags = ({ tag, publicIds, options }) =>
  new Promise((resolve, reject) => {
    api({
      url: `${API_VIDEO}/deletetags`,
      method: 'POST',
      data: { tag, publicIds, options }
    })
      .then(res => {
        resolve(res.data);
      })
      .catch(e => {
        reject(e);
      });
  });

export const handleLatestTag = ({ folder }) =>
  new Promise((resolve, reject) => {
    api({
      url: `${API_VIDEO}/handlelatest`,
      method: 'POST',
      data: { folder: folder }
    })
      .then(res => {
        resolve(res.data);
      })
      .catch(e => {
        reject(e);
      });
  });

export const upload = ({ content }) =>
  new Promise((resolve, reject) => {
    const hash = hashCode(content);
    const cloud_name = 'dakp804eh';
    api({
      url: `${API_VIDEO}/gallery`,
      method: 'POST',
      data: { query: `folder=woxo-templates-svg AND filename=resource${hash}`, limit: 1 }
    })
      .then(response => {
        if (isArray(response.data.resources) && response.data.resources[0])
          resolve(response.data.resources[0]);
        else {
          const data = new FormData();
          data.append('file', content);
          data.append('upload_preset', 'first_preset');
          data.append('cloud_name', cloud_name);
          data.append('public_id', `woxo-templates-svg/resource${hash}`);

          axios({
            url: `https://api.cloudinary.com/v1_1/${cloud_name}/image/upload`,
            method: 'POST',
            data
          })
            .then(res => {
              resolve(res.data);
            })
            .catch(e => {
              reject(e);
            });
        }
      })
      .catch(e => {
        reject(e);
      });
  });
