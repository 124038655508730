import React from 'react';
import axios from 'axios';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  linkTitle: {
    textTransform: 'uppercase',
    fontSize: 16,
    fontWeight: 800
  },
  link: {
    fontSize: 16,
    fontWeight: '300',
    margin: '12px 0px',
    '& a': {
      color: '#fff',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline'
      }
    },
    '@media only screen and (max-width: 425px)': {
      textAlign: 'center'
    }
  }
}));

const PostsList = () => {
  const classes = useStyles();

  const [posts, setPosts] = React.useState([]);

  React.useEffect(function effectFunction() {
    const postsList = JSON.parse(sessionStorage.getItem('postsList'));
    const today = new Intl.DateTimeFormat('en-Us', {
      year: 'numeric',
      month: 'short',
      day: '2-digit'
    }).format(new Date());

    const loadPosts = () => {
      axios.get('/api/blog/list').then(value => {
        setPosts(value.data.slice(0, 3));
        sessionStorage.setItem(
          'postsList',
          JSON.stringify({ today: today, posts: value.data.slice(0, 3) })
        );
      });
    };
    if (postsList && postsList.today === today) {
      setPosts(postsList.posts);
    } else {
      loadPosts();
    }
  }, []);

  const createMarkup = html => {
    return { __html: html };
  };

  return (
    <>
      {!!posts.length && <Typography className={classes.linkTitle}>Top blog posts</Typography>}
      {posts.map(v => (
        <Typography key={v.id} className={classes.link}>
          {}
          <a
            href={v.link}
            target="_blank"
            rel="noreferrer"
            dangerouslySetInnerHTML={createMarkup(v.title.rendered)}
          />
        </Typography>
      ))}
    </>
  );
};

export default PostsList;
