import { upload } from '~/services/cloudinary.services';
import { clone } from '~/helpers/javascript';

const uploadMediasPromises = (local, handleUploadProgressAction, options) =>
  new Promise((resolve, reject) => {
    upload({
      file: local,
      options,
      onUploadProgress: progressEvent => {
        handleUploadProgressAction(progressEvent, local.name);
      }
    })
      .then(asset => {
        resolve({ ...asset, name: local.name });
      })
      .catch(e => {
        resolve(false);
        console.log(e);
      });
  });

const totalProgress = progress => {
  let total = 0;
  let sum = 0;
  for (const key in progress) {
    if (Object.hasOwnProperty.call(progress, key)) {
      const element = progress[key];
      if (element !== 100) {
        total++;
        sum += element;
      }
    }
  }
  if (sum === 0) sum = 100;
  if (total === 0) total = 1;
  return sum / total;
};

const determinePercentValue = value => {
  let total = value;

  if (total < 10) total = 5;
  if (total >= 10 && total < 25) total = 15;
  if (total >= 25 && total < 40) total = 30;
  if (total >= 40 && total < 60) total = 50;
  if (total >= 60 && total < 80) total = 70;
  if (total >= 75 && total <= 99) total = 90;
  if (total === 100) total = 100;

  return total;
};

export const useUploadStore = (set, get) => ({
  //states
  uploading: false,
  uploadingProgress: {},

  //actions
  handleUploadProgressAction: (progressEvent, fileName) => {
    var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    set(state => {
      let _progress = Object.assign({}, state.uploadingProgress);
      _progress[`${fileName}`] = percentCompleted;
      let total = determinePercentValue(Math.round(totalProgress(_progress)));
      if (state.downloadStatus === 'uploading' && state.downloadPercent < total)
        state.setDownloadPercentAction(total);
      return { uploadingProgress: _progress };
    });
  },

  uploadingVideosAction: (newMedias, handleUploadProgressAction, options) => {
    if (get().downloadStatus !== 'generating' || get().downloadStatus !== 'downloading')
      get().setDownloadPercentAction(0);
    const promises = [];
    for (let index = 0; index < newMedias.length; index++) {
      const local = newMedias[index];
      promises.push(uploadMediasPromises(local, handleUploadProgressAction, options));
    }
    // console.log('promises', promises);
    Promise.all(promises).then(assets => {
      // console.log('assets', assets);
      let _artboardVideoData = clone(get().artboardVideoData);
      _artboardVideoData.map(({ medias, ...sc }) => {
        const _medias = medias.map(m => {
          const asset = assets.find(a => a.name === m.path);
          if (asset) return Object.assign(m, { preview: asset.url, status: 'uploaded', asset });
          return m;
        });
        return { ...sc, medias: _medias };
      });
      // console.log('finish', _artboardVideoData);
      // onUploadFinished(_artboardVideoData);
      get().trackingAction({
        event: 'onButtonClick',
        category: 'bulk-creator',
        action: `media-uploaded`
      });
      const _isUploading = totalProgress(get().uploadingProgress) === 100 ? false : true;
      set({ uploading: _isUploading });
      get().setArtboardVideoDataAction(_artboardVideoData);
      get().addProjectVersionsAction();
      // console.log('artboardVideoData when finish');
    });
  }
});
