import { sampleGradients } from './gradients';
import { shiftPushArray } from '~/helpers/javascript';
import { presets } from './presets';

export const sampleContentVideoData = videos => {
  const numberVideos = videos[0]?.videoDefinition?.length;
  const category = videos[0]?.videoDefinition[0]?.type;
  let contentVideoData = [];

  let _presetsStyle = presets(category);

  for (let i = 0; i < numberVideos; i++) {
    const { Font, BackgroundColor, TextColor, TextStyle, Music } = _presetsStyle[i];
    contentVideoData.push({
      font: Font,
      backgroundColor: BackgroundColor,
      textColor: TextColor,
      textStyle: TextStyle,
      music: Music,
      gradient: { type: shiftPushArray(sampleGradients), scenes: [] }
    });
  }

  return contentVideoData;
};
