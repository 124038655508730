import { sampleMusics } from '../data/music';
import { sampleGradients } from '../data/gradients';
import { presets } from '../data/presets';

export const generateContentVideoData = (
  totalVideos = 0,
  quantity = 0,
  music = 'Inspirational',
  category = 'Quotes'
) => {
  let _contentVideoData = [];

  let _musics = sampleMusics.find(m => m.mood === music)?.music.sort(() => Math.random() - 0.5);

  const unusedPreset = presets(category).filter((p, i) => i + 1 > totalVideos - 1);
  const presetsUsed = presets(category).filter((p, i) => i + 1 < totalVideos);

  let _presetsStyle = [...unusedPreset, ...presetsUsed];

  let _gradient = sampleGradients.sort(() => Math.random() - 0.5);

  let g = 0;
  let m = 0;
  let p = 0;
  for (let i = 0; i < quantity; i++) {
    if (_gradient.length <= g) g = 0;
    if (_musics.length <= m) m = 0;
    if (_presetsStyle.length <= p) p = 0;
    const { Font, BackgroundColor, TextColor, TextStyle } = _presetsStyle[p];
    _contentVideoData.push({
      font: Font,
      backgroundColor: BackgroundColor,
      textColor: TextColor,
      textStyle: TextStyle,
      music: _musics[m],
      gradient: { type: _gradient[g], scenes: [] }
    });
    g++;
    m++;
    p++;
  }

  return _contentVideoData;
};
