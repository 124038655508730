export const textsByCategory = def => {
  let texts = {
    title: '',
    text: ''
  };
  if (def)
    switch (def.type) {
      case 'Quotes':
        const separators = [
          ' \u2013 ',
          '\u2013 ',
          ' \u2013',
          ' \u2014 ',
          '\u2014 ',
          ' \u2014',
          ' \u2015 ',
          '\u2015 ',
          ' \u2015',
          ' \u002d ',
          '\u002d ',
          ' \u002d',
          '" ',
          '” '
        ];
        const separatorsText = [];
        separators.map(s => {
          if (def.text.indexOf(s) !== -1) {
            separatorsText.push(s);
          }
        });

        if (separatorsText.length) {
          const separator = separatorsText[0];
          const _texts = def.text.split(separator);
          texts.title =
            _texts[0].trim() + (separator === '" ' ? '"' : separator === '” ' ? '”' : '');
          texts.text = _texts[1].trim();
        } else {
          texts.title = def.text.trim();
          texts.text = '';
        }

        break;
      default:
        texts.title = def.text;

        break;
    }

  return texts;
};

export const textsByCategoryV1 = def => {
  let texts = {
    title: '',
    text: ''
  };
  if (def)
    switch (def.type) {
      case 'Quotes':
        const separators = [
          ' \u2013 ',
          '\u2013 ',
          ' \u2013',
          ' \u2014 ',
          '\u2014 ',
          ' \u2014',
          ' \u2015 ',
          '\u2015 ',
          ' \u2015',
          ' \u002d ',
          '\u002d ',
          ' \u002d',
          '" ',
          '” '
        ];
        const separatorsText = [];
        separators.map(s => {
          def.v1?.map(t => {
            if (t.text.indexOf(s) !== -1) {
              separatorsText.push(s);
            }
          });
        });

        if (separatorsText.length) {
          let title = '';
          let text = '';
          for (let i = 0; i < def.v1?.length; i++) {
            const t = def.v1[i];
            for (let idx = 0; idx < separatorsText.length; idx++) {
              const s = separatorsText[idx];
              const _texts = t.text.split(s);
              if (_texts.length === 2) {
                title += _texts[0].trim() + (s === '" ' ? '"' : s === '” ' ? '”' : '');
                text += _texts[1].trim();
                if (i < def.v1.length - 1) {
                  title += '~';
                  text += '~';
                }
                break;
              } else if (idx === separatorsText.length - 1) {
                title += _texts[0].trim();
                text += '';
                if (i < def.v1.length - 1) {
                  title += '~';
                  text += '~';
                }
                break;
              }
            }
          }
          texts.title = title;
          texts.text = text;
        } else {
          let title = '';
          let text = '';
          def.v1?.map((t, i) => {
            title += t.text.trim();
            text += '';
            if (i < def.v1.length - 1) {
              title += '~';
              text += '~';
            }
          });
          texts.title = title;
          texts.text = text;
        }

        break;
      default:
        let title = '';
        def.v1?.map((t, i) => {
          title += t.text.trim();
          if (i < def.v1.length - 1) {
            title += '~';
          }
        });
        texts.title = title;

        break;
    }

  return texts;
};
