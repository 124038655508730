import { getSceneDuration } from '../../data/template-generator/video-suggestions/_helpers';
import { clone } from '../../helpers/javascript';

export const reduceDataScenes = _data => {
  const data = clone(_data);

  const scenes = [];
  const MAX_DURATION = 60;

  //Creating scene for each Media-Title-Text and remove blank ones
  for (const key in data) {
    if (Object.hasOwnProperty.call(data, key)) {
      if (key.startsWith('Media ') || key.startsWith('Title ') || key.startsWith('Text ')) {
        const element = data[key];
        const keySplitted = key.split(' ');
        const mediaNumber = parseInt(keySplitted[1]);

        if (
          data[`Media ${mediaNumber}`] &&
          data[`Text ${mediaNumber}`] &&
          data[`Title ${mediaNumber}`] &&
          !data[`Media ${mediaNumber}`].trim() &&
          !data[`Text ${mediaNumber}`].trim() &&
          !data[`Title ${mediaNumber}`].trim()
        ) {
          delete data[`Media ${mediaNumber}`];
          delete data[`Text ${mediaNumber}`];
          delete data[`Title ${mediaNumber}`];
        } else {
          if (!scenes[mediaNumber - 1]) {
            scenes[mediaNumber - 1] = {};
          }
          scenes[mediaNumber - 1][keySplitted[0]] = element;
        }
      }
    }
  }

  // Updating ScenesLength for overdose or lack of them
  const scenesSplit = data.ScenesLength
    ? Array.isArray(data.ScenesLength)
      ? data.ScenesLength
      : data.ScenesLength.split(',')
    : [];

  if (scenesSplit.length < scenes.length) {
    for (let i = scenes.length - scenesSplit.length; i > 0; i--) {
      scenesSplit.push('smart');
    }
  }

  if (scenesSplit.length > scenes.length) {
    for (let i = scenesSplit.length; i > scenes.length; i--) {
      scenesSplit.pop();
    }
  }

  const newSceneSplit = scenesSplit.map(item => {
    if (Number(item)) return Number(item);
    else return item;
  });

  data.ScenesLength = newSceneSplit;

  let totalDuration = 0;
  scenes.map((s, i) => {
    const duration = newSceneSplit[i] !== 'smart' ? newSceneSplit[i] : getSceneDuration(s) + 1; // auto-calculate duration segun cantidad de palabras + (1s) tiempo del efecto del text
    scenes[i].duration = duration;
    scenes[i].index = i + 1;
    totalDuration += duration;
  });

  if (totalDuration > MAX_DURATION) {
    const maxScenesDuration = MAX_DURATION / scenes.length;

    const regularDurationScenes = scenes.filter(sc => sc.duration <= maxScenesDuration);
    const exceededDurationScenes = scenes.filter(sc => sc.duration > maxScenesDuration);

    let totalRegularDuration = 0;
    regularDurationScenes.map((s, i) => {
      totalRegularDuration += s.duration;
    });

    let totalExceededDuration = 0;
    exceededDurationScenes.map((s, i) => {
      totalExceededDuration += s.duration;
    });

    const leftDuration = MAX_DURATION - totalRegularDuration;

    const leftDurationSc = leftDuration - exceededDurationScenes.length * maxScenesDuration;
    exceededDurationScenes.map((esc, i) => {
      let optimalDurationPlus =
        ((esc.duration - maxScenesDuration) * leftDurationSc) / totalExceededDuration;

      optimalDurationPlus += maxScenesDuration;

      const optimalWordCut = optimalDurationPlus < 1 ? 1 : Math.floor(optimalDurationPlus) * 2;

      exceededDurationScenes[i].optimalWordCut = optimalWordCut;
      if (esc.Title !== '' || esc.Text !== '') {
        const totalText = `${esc.Title} ${esc.Text}`;
        const textWordCount = totalText.trim().split(/\s+/).length;

        const titleProportion =
          (esc.Title.trim().split(/\s+/).length / textWordCount) * optimalWordCut;
        const textProportion =
          (esc.Text.trim().split(/\s+/).length / textWordCount) * optimalWordCut;

        exceededDurationScenes[i].Title = truncateString(
          esc.Title,
          titleProportion > 1 ? Math.floor(titleProportion) : 1
        );
        exceededDurationScenes[i].Text = truncateString(
          esc.Text,
          textProportion > 1 ? Math.floor(textProportion) : 1
        );

        data[`Title ${exceededDurationScenes[i].index}`] = exceededDurationScenes[i].Title;
        data[`Text ${exceededDurationScenes[i].index}`] = exceededDurationScenes[i].Text;
      }
    });
  }

  return data;
};

const truncateString = (str, no_words) => {
  const words = str.trim().split(/\s+/);

  if (words && words.length > no_words) {
    const text = `${str.trim().split(/\s+/).splice(0, no_words).join(' ')}`;
    if (text.indexOf('...') === text.length - 3) return text;
    return `${text}...`;
  }
  return str;
};
