import { v4 } from 'uuid';
import templateDefinition from '../../data/template-generator/templateDefinitionGeneric';
import { getSceneDuration } from '../../data/template-generator/video-suggestions/_helpers';
import { clone } from '../../helpers/javascript';

export const adaptiveFromData = data => {
  const _data = clone(data);
  const _s = templateDefinition.schema;

  const { clips, ...template } = templateDefinition.json;
  const { layers, ...aclips } = clips[0];

  let media = {};
  let title = {};
  let text = {};
  const defaultSchema = [];

  _s.forEach(e => {
    switch (e.title) {
      case 'Media':
        media = e;
        break;
      case 'Title':
        title = e;
        break;
      case 'Text':
        text = e;
        break;

      default:
        defaultSchema.push(e);
        break;
    }
  });
  const result = [];

  _data.map((d, dindex) => {
    const scenes = [];
    const _schema = [];
    const _clips = [];

    defaultSchema.forEach(e => {
      if (!d[e.name] && e.default) d[e.name] = e.default;
    });

    for (const key in d) {
      const item = key.match(/[1-9]+/g);

      if (Object.hasOwnProperty.call(d, key)) {
        if (key.startsWith('Media ') || key.startsWith('Title ') || key.startsWith('Text ')) {
          const element = d[key];
          const keySplitted = key.split(' ');
          const mediaNumber = parseInt(keySplitted[1]);
          if (!scenes[mediaNumber - 1]) {
            scenes[mediaNumber - 1] = {};
          }
          scenes[mediaNumber - 1][keySplitted[0]] = element;

          switch (keySplitted[0]) {
            case 'Media':
              {
                const m = JSON.stringify(media);
                _schema.push(JSON.parse(m.replace(/#/g, keySplitted[1])));
              }
              break;
            case 'Title':
              {
                const tl = JSON.stringify(title);
                _schema.push(JSON.parse(tl.replace(/#/g, keySplitted[1])));
              }
              break;
            case 'Text':
              {
                const tx = JSON.stringify(text);
                _schema.push(JSON.parse(tx.replace(/#/g, keySplitted[1])));
              }
              break;

            default:
              break;
          }
        } else if (defaultSchema.find(s => s.name === key)) {
          _schema.push(defaultSchema.find(s => s.name === key));
        }
      }
    }

    scenes.forEach((e, i) => {
      const _layers = [];
      let textFlag = false;
      for (const key in e) {
        if (Object.hasOwnProperty.call(e, key)) {
          if (key === 'Media') {
            const mediaTmpl = JSON.stringify(layers[0]).replace(/#/g, i + 1);
            _layers.push(JSON.parse(mediaTmpl));
          }
          if (key === 'Title' || key === 'Text') {
            const textTmpl = JSON.stringify(layers[1]).replace(/#/g, i + 1);
            if (textFlag) _layers.push(JSON.parse(textTmpl));
            textFlag = true;
          }
        }
      }

      if (d.BackgroundGradient && d.BackgroundGradient !== '') {
        d.UseBG = true;
      }

      _layers.push(layers[2]);
      const duration = d.ScenesLength
        ? d.ScenesLength[i] === 'smart'
          ? getSceneDuration(e)
          : d.ScenesLength[i]
        : getSceneDuration(e);

      _clips.push({ ...aclips, duration: duration, layers: _layers });
    });

    result.push({
      schema: _schema.sort((a, b) => a.groupIdx - b.groupIdx),
      template: { ...template, clips: _clips },
      data: (!d.id && { ...d, id: v4() }) || d
    });
  });

  return result;
};
