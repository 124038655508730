import { getSceneDuration } from '../../data/template-generator/video-suggestions/_helpers';
import { clone, isUrl, isColor, isGradientType, isMood } from '../../helpers/javascript';

export const parseCcFromText = (data, changedProperties, keyName) => {
  const _data = clone(data);
  const optionalText = keyName.startsWith('Title') ? 'Title' : 'Text';

  if (/^Title/g.test(keyName)) {
    const item = keyName.match(/[1-9]+/g);
    const column = item ? Number(item[0]) - 1 : null;
    const index = _data.indexOf(_data.find(e => e.data.id === changedProperties.id));
    const elements = changedProperties[keyName].split('~');
    const elementsText = _data[index].data[`Text ${column + 1}`].split('~');
    const max = elements.length > elementsText.length ? elements.length : elementsText.length;
    const clip = _data[index].template.clips[column];
    const layerIndex = clip.layers.indexOf(
      clip.layers.find(l => l.text && l.text.includes(`{{${keyName}}}`))
    );
    if (layerIndex > -1) {
      const removedLayer = clone(_data[index].template.clips[column].layers[layerIndex]);
      _data[index].template.clips[column].layers[layerIndex].opaque = true;
      _data[index].template.clips[column].layers = _data[index].template.clips[
        column
      ].layers.filter(l => !l.subLayer || l.subLayer !== `Scene-${column + 1}`);
      delete removedLayer.opaque;
      Object.keys(_data[index].data).map(k => {
        if (k.startsWith(`cc-Title ${column + 1}`)) delete _data[index].data[k];
      });
      _data[index].data[`Title ${column + 1}`] = changedProperties[keyName];
      let time = 0;
      for (let i = 0; i < max; i++) {
        const e = elements[i];
        const stop = getSceneDuration({
          Title: e || '',
          Text: elementsText[i] || ''
        });
        let text = `{{cc-Title ${column + 1}-${i}}}\n`;
        if (elementsText.length > 1)
          text = `{{cc-Title ${column + 1}-${i}}}\n{{cc-Text ${column + 1}-${i}}}`;
        else if (i === 0) text = `{{cc-Title ${column + 1}-${i}}}\n{{Text ${column + 1}}}`;
        _data[index].template.clips[column].layers.splice(layerIndex, 0, {
          ...removedLayer,
          text,
          subLayer: `Scene-${column + 1}`,
          start: time,
          stop: time + stop,
          _parentLayer: {
            text: `{{Title ${column + 1}}}\n{{Text ${column + 1}}}`,
            reference: [`Title ${column + 1}`, `Text ${column + 1}`]
          }
        });
        time += stop;
        _data[index].data[`cc-Title ${column + 1}-${i}`] = e;
      }
      if (elementsText.length > 1 || elements.length > 1) {
        _data[index].template.clips[column].duration = time;
        _data[index].template.clips[column].durationMode = 'manual';
      } else delete _data[index].template.clips[column].durationMode;
    }
  } else if (/^Text/g.test(keyName)) {
    const item = keyName.match(/[1-9]+/g);
    const column = item ? Number(item[0]) - 1 : null;
    const index = _data.indexOf(_data.find(e => e.data.id === changedProperties.id));
    const elements = changedProperties[keyName].split('~');
    const elementsTitle = _data[index].data[`Title ${column + 1}`].split('~');
    const max = elements.length > elementsTitle.length ? elements.length : elementsTitle.length;
    const clip = _data[index].template.clips[column];
    const layerIndex = clip.layers.indexOf(
      clip.layers.find(l => l.text && l.text.includes(`{{${keyName}}}`))
    );
    if (layerIndex > -1) {
      const removedLayer = clone(_data[index].template.clips[column].layers[layerIndex]);
      _data[index].template.clips[column].layers[layerIndex].opaque = true;
      _data[index].template.clips[column].layers = _data[index].template.clips[
        column
      ].layers.filter(l => !l.subLayer || l.subLayer !== `Scene-${column + 1}`);
      delete removedLayer.opaque;
      Object.keys(_data[index].data).map(k => {
        if (k.startsWith(`cc-Text ${column + 1}`)) delete _data[index].data[k];
      });
      _data[index].data[`Text ${column + 1}`] = changedProperties[keyName];
      let time = 0;
      for (let i = 0; i < max; i++) {
        const e = elements[i];
        const stop = getSceneDuration({
          Title: e || '',
          Text: elementsTitle[i] || ''
        });
        let text = `\n{{cc-Text ${column + 1}-${i}}}`;
        if (elementsTitle.length > 1)
          text = `{{cc-Title ${column + 1}-${i}}}\n{{cc-Text ${column + 1}-${i}}}`;
        else if (i === 0) text = `{{Title ${column + 1}}}\n{{cc-Text ${column + 1}-${i}}}`;
        _data[index].template.clips[column].layers.splice(layerIndex, 0, {
          ...removedLayer,
          text,
          subLayer: `Scene-${column + 1}`,
          start: time,
          stop: time + stop,
          _parentLayer: {
            text: `{{Title ${column + 1}}}\n{{Text ${column + 1}}}`,
            reference: [`Title ${column + 1}`, `Text ${column + 1}`]
          }
        });
        time += stop;
        _data[index].data[`cc-Text ${column + 1}-${i}`] = e;
      }
      if (elementsTitle.length > 1 || elements.length > 1) {
        _data[index].template.clips[column].duration = time;
        _data[index].template.clips[column].durationMode = 'manual';
      } else delete _data[index].template.clips[column].durationMode;
    }
  }
  return _data;
};
