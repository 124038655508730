import { v4 } from 'uuid';
import { clone } from '../../../../helpers/javascript';

/**
 * TODO: Implement the 1 media use case. Actually not supported
 * @param {*} videoData
 */
const getScenesFromData = videoData => {
  const scenes = [];
  Object.keys(videoData).forEach(key => {
    const value = videoData[key];
    if (key.startsWith('Media ') || key.startsWith('Title ') || key.startsWith('Text ')) {
      const keySplitted = key.split(' ');
      const mediaNumber = parseInt(keySplitted[1]);
      if (!scenes[mediaNumber - 1]) {
        scenes[mediaNumber - 1] = {};
      }
      scenes[mediaNumber - 1][keySplitted[0]] = value;
    }
  });
  return scenes;
};

const getSceneDuration = scene => {
  const totalText = `${scene.Title} ${scene.Text}`;
  let wordsPerSecond = 1;
  const words = totalText.trim().split(/\s+/).length;

  // si hay muchas palabras el cerebro las lees mas rapido
  if (words > 6) wordsPerSecond = 2;
  else if (words > 10) wordsPerSecond = 3;

  let duration = words / wordsPerSecond;

  // si hay 1 palabra el cerebro necesita mas tiempo que cuando no hay ninguna
  if (duration < 3 && words > 0) duration = 4;
  // si hay solo una imagen segarantiza que este un tiempo minimo
  else if (duration < 3) duration = 3;
  if (scene.animatedByLetter) duration += (scene.start || 0) + words * 0.05 * wordsPerSecond + 2;
  return duration;
};

/**
 * The purpose of this function is autocalculate clips presets for the user
 * @param {*} videoData
 * @param {*} template
 */
const getSmartTemplate = (videoData, template) => {
  // agrupar las text, title y medias en escenas.
  const copyVideoData = { ...videoData };
  const copyTemplate = { ...template };
  const scenes = getScenesFromData(copyVideoData);

  // calcular la duracion del clip
  // calcular el tammano del total text = title + text
  const scenesWithDuration = scenes.map(scene => {
    const sceneDuration = getSceneDuration(scene);
    scene.duration = sceneDuration;
    return scene;
  });

  scenesWithDuration.forEach((scene, index) => {
    if (copyTemplate.clips && copyTemplate.clips[index]) {
      copyTemplate.clips[index].duration = scene.duration;
      copyTemplate.clips[index].otherDuration = scene.duration;
    }
  });
  return copyTemplate;
};

const changeMediaContent = data => {
  const copyVideoData = { ...data };
  const scenes = getScenesFromData(copyVideoData);
  let duration = 0;

  const scenesWithDuration = scenes.map(scene => {
    const sceneDuration = getSceneDuration(scene);
    scene.duration = sceneDuration;
    return scene;
  });

  scenesWithDuration.forEach(s => {
    duration += Number(s.duration);
  });

  Object.keys(copyVideoData).forEach(key => {
    if (key.startsWith('Media ') && copyVideoData[key].indexOf('//res.cloudinary.com/') !== -1) {
      const url = copyVideoData[key].split('/upload/');

      const originalURL = copyVideoData[key];

      const previewURL =
        `${url[0]}/upload/` +
        `q_50${
          copyVideoData[key].indexOf('/video/upload/') !== -1
            ? `,du_${scenesWithDuration[Number(key.split('Media ')[1]) - 1].duration},fps_1-16/`
            : '/'
        }${url[1]}`;
      const coverURL =
        `${url[0]}/upload/` +
        `q_5/${copyVideoData[key].indexOf('/video/upload/') !== -1 ? ',du_1,fps_1-16/' : ''}${
          url[1]
        }`;

      copyVideoData[key] = `${originalURL}${
        originalURL.indexOf('?') !== -1 ? '&' : '?'
      }preview=${encodeURIComponent(previewURL)}&cover=${encodeURIComponent(coverURL)}`;
    } else if (key.startsWith('Music')) {
      const url = copyVideoData[key].split('/upload/');

      const originalURL = copyVideoData[key];

      const previewURL = `${url[0]}/upload/du_${duration}/${url[1]}`;

      copyVideoData[key] = `${originalURL}?preview=${encodeURIComponent(previewURL)}`;
    }
  });

  return copyVideoData;
};

/**
 * Aplicando un Hight Order Function a este caso de uso especifico
 * nos evito tener que repetir todo ese codigo de abajo
 * en todos los suggestions especificos. Ademas de que si se va a
 * agregar un pipeline de transformaciones "smart" es muy facil de componer.
 *
 * HOC Explanation
 * https://blog.bitsrc.io/understanding-higher-order-functions-in-javascript-75461803bad
 *
 * @param {*} getSuggestions
 */
const withSmartTemplatesHOC = getSuggestions => {
  const suggestions = getSuggestions();
  const suggestionsWithSmartTemplates = suggestions.map(suggestion => {
    suggestion.template = clone(getSmartTemplate(suggestion.data, suggestion.template));
    suggestion.data = clone(changeMediaContent(suggestion.data));
    return suggestion;
  });
  return suggestionsWithSmartTemplates;
};

const videoShapes = ['9:16', '16:9', '1:1', '4:5'];
const getRandomNumberInRange = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

const getSuggestionsWithShuffledStylesAndCommonAttrs = (
  content,
  staticAttr,
  styles,
  template,
  schema
) => {
  const shuffledStyles = [...styles].sort(() => 0.5 - Math.random());
  let stylesIndex = 0;
  const finalSuggestions = content.map((item, index) => {
    const mergedData = {
      ...{ id: v4() },
      ...item,
      ...staticAttr,
      ...shuffledStyles[stylesIndex]
      // ...{ 'Video Shape': videoShapes[getRandomNumberInRange(0, 3)] }
    };
    stylesIndex += 1;
    if (stylesIndex >= shuffledStyles.length) {
      stylesIndex = 0;
    }
    const suggestion = {
      data: mergedData,
      template: template,
      schema: schema
    };
    return suggestion;
  });
  return finalSuggestions;
};

export {
  getSceneDuration,
  getSmartTemplate,
  getScenesFromData,
  withSmartTemplatesHOC,
  getSuggestionsWithShuffledStylesAndCommonAttrs
};
