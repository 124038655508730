import { makeStyles } from '@material-ui/core/styles';

export const select = makeStyles(theme => ({
  root: {
    border: '1px solid rgb(33, 133, 208)',
    backgroundColor: '#bbd7f8',
    '& fieldset': {
      border: 'none !important'
    },
    '& input': {
      fontSize: 14,
      textAlign: 'center'
    },
    '& .MuiAutocomplete-inputRoot': {
      paddingRight: '40px !important',
      padding: '0px !important'
    },
    '& .MuiAutocomplete-endAdornment': {
      right: 0
    }
  },
  popper: {
    '& *': {
      fontSize: 14
    }
  },
  popperSelect: {
    '& fieldset': {
      border: 'none !important'
    },
    '& input': {
      fontSize: 14
    },
    '& .MuiAutocomplete-inputRoot': {
      padding: '0px !important'
    },
    '& .MuiAutocomplete-endAdornment': {
      right: 0
    }
  }
}));

export const text = makeStyles(theme => ({
  root: {
    width: '100%',
    border: '1px solid rgb(33, 133, 208)',
    backgroundColor: '#bbd7f8',
    padding: '6px 12px',
    height: '100%',
    outline: 0,
    '&.error': {
      borderColor: 'red'
    },
    resize: 'none',
    position: 'absolute',
    top: 0
  }
}));

export const textCaption = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    border: 'none',
    backgroundColor: 'transparent',
    // padding: '6px 12px',
    height: '100%',
    outline: 0,
    '&.error': {
      borderColor: 'red'
    },
    resize: 'none'
    //position: 'absolute',
    //top: 0
  }
}));
